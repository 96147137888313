<!-- 短信模板信息维护 -->
<template>
  <div>
    <div>
      <div class="tool">
        <div>
          <a-input-search
            v-model:value="searchParam.searchValue"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
          />
        </div>
        <div>
          <a-button type="primary" @click.stop="doAddTemplate">
            <PlusOutlined />添加短信模板
          </a-button>
        </div>
      </div>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 1000 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="templateList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #status="{ record }">
          <a-switch
            size="small"
            checkedChildren="正常"
            unCheckedChildren="禁用"
            v-model:checked="record.status"
            @change="doSetStatus(record)"
          />
        </template>
        <template #action="{ record }">
          <span>
            <a-button
              type="link"
              size="small"
              @click="doDeleteTemplate(record)"
            >
              <SvgIcon iconName="delete_red" className="svg15" />
              <span style="color: red">删除</span>
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doEditTemplate(record)">
              <EditOutlined />编辑
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click="doDetailTemplate(record)"
            >
              <FileTextOutlined />详情
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doTemplateItem(record)">
              <SettingOutlined />配置
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div>
      <TemplateForm
        :modalVisible="modalVisible"
        :isCreate="isCreate"
        :editData="editData"
        @handleModalVisible="handleModalVisible"
        @saveCallBack="saveCallBack"
      />
      <TemplateItem
        :itemModalVisible="itemModalVisible"
        :currentInfo="templateInfo"
        @handleItemModalVisible="handleItemModalVisible"
      />
    </div>
  </div>
</template>
<script>
import {
  PlusOutlined,
  EditOutlined,
  SettingOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import {
  getTemplateInfoListPage,
  updateTemplateStatus,
  deleteTemplateInfo,
} from "@/api/message/template";
import TemplateForm from "./components/TemplateForm.vue";
import TemplateItem from "./components/TemplateItem.vue";
import { message, Modal } from "ant-design-vue";
import SvgIcon from "@/components/Svgicon";
import moment from "moment";
export default defineComponent({
  name: "Template",
  components: {
    PlusOutlined,
    EditOutlined,
    SettingOutlined,
    FileTextOutlined,
    TemplateForm,
    TemplateItem,
    SvgIcon,
  },
  setup() {
    const state = reactive({
      loading: false,
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      isCreate: 0, //0新增  1编辑
      templateInfo: {
        tplId: null,
        tplName: "",
        tplContent: "",
      },
      editData: {
        id: null,
        tplName: "",
        tplContent: "",
        tplType: null,
        accountId: JSON.parse(sessionStorage.getItem("userinfo")).id,
      },
      modalVisible: false,
      itemModalVisible: false,
      searchParam: {
        searchValue: "",
        rid: JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id,
        uid: JSON.parse(sessionStorage.getItem("userinfo")).id,
        pageIndex: 1,
        pageSize: 10,
      },
      templateList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "模板名称",
        dataIndex: "tplName",
        key: "tplName",
        width: 200,
      },
      {
        title: "模板类型",
        dataIndex: "templateTypeInfo.item",
        align: "center",
        ellipsis: true,
        key: "tplType",
        width: 150,
      },
      {
        title: "模板状态",
        dataIndex: "status",
        align: "center",
        key: "status",
        width: 150,
        slots: {
          customRender: "status",
        },
      },
      {
        title: "所属账户",
        dataIndex: "accountInfo.uname",
        key: "accountName",
        width: 150,
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        align: "center",
        key: "createDate",
        width: 200,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:ss:mm") : "";
        },
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: 400,
        fixed: "right",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      initTemplateList(state.searchParam);
    });
    const initTemplateList = (searchParam) => {
      state.loading = true;
      getTemplateInfoListPage(searchParam).then((resp) => {
        if (resp) {
          state.templateList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      (state.searchParam.pageIndex = 1),
        (state.searchParam.pageSize = 10),
        (state.searchParam.rid = state.userinfo.roles[0].id),
        (state.searchParam.uid = state.userinfo.id);
      initTemplateList(state.searchParam);
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      state.searchParam.rid = state.userinfo.roles[0].id;
      state.searchParam.uid = state.userinfo.id;
      initTemplateList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      state.searchParam.rid = state.userinfo.roles[0].id;
      state.searchParam.uid = state.userinfo.id;
      initTemplateList(state.searchParam);
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const handleItemModalVisible = (flag) => {
      state.itemModalVisible = flag;
    };
    const doSetStatus = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将更新【" + record.tplName + "】模板的状态，是否继续？"
        ),
        onOk() {
          updateTemplateStatus({
            id: record.id,
            val: record.status ? 1 : 0,
          }).then((resp) => {
            if (resp) {
              initTemplateList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消模板状态更新操作！！！");
          initTemplateList(state.searchParam);
        },
      });
    };
    const doDeleteTemplate = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将永久删除【" + record.tplName + "】模板，是否继续？"
        ),
        onOk() {
          deleteTemplateInfo({ id: record.id, logic: true }).then((resp) => {
            if (resp) {
              initTemplateList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消删除模板的操作！！！");
        },
      });
    };
    // const doDeleteTemplates = () => {
    //   if (state.selectedRowKeys.length > 0) {
    //     Modal.confirm({
    //       title: "温馨提示",
    //       icon: createVNode(ExclamationCircleOutlined),
    //       okText: "确认",
    //       cancelText: "取消",
    //       content: createVNode(
    //         "div",
    //         { style: "color:red;" },
    //         "此操作讲永久删除所选择的用户，是否继续？"
    //       ),
    //       onOk() {
    //         deleteTemplates({ ids: state.selectedRowKeys }).then((resp) => {
    //           if (resp) {
    //             initTemplateList(state.searchParam);
    //           }
    //         });
    //       },
    //       onCancel() {
    //         message.info("已取消删除用户操作！！！");
    //       },
    //     });
    //   } else {
    //     message.info("请先选择要删除的记录行！！！");
    //   }
    // };
    const doAddTemplate = () => {
      handleModalVisible(true);
      state.isCreate = 0;
    };
    const doEditTemplate = (record) => {
      handleModalVisible(true);
      state.isCreate = 1;
      state.editData = {
        id: record.id,
        tplName: record.tplName,
        tplContent: record.tplContent,
        tplType: record.tplType,
        accountId: record.accountId,
      };
    };
    const doDetailTemplate = (record) => {
      handleModalVisible(true);
      state.isCreate = 2;
      state.editData = {
        id: record.id,
        tplName: record.tplName,
        tplContent: record.tplContent,
        tplType: record.tplType,
        accountId: record.accountId,
      };
    };
    const doTemplateItem = (record) => {
      handleItemModalVisible(true);
      state.templateInfo = {
        tplId: record.id,
        tplName: record.tplName,
        tplContent: record.tplContent,
      };
    };
    const saveCallBack = (code) => {
      if (code == 200) {
        initTemplateList(state.searchParam);
      }
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initTemplateList,
      onSearch,
      onChangePage,
      onShowSizeChange,
      handleModalVisible,
      handleItemModalVisible,
      doSetStatus,
      doDeleteTemplate,
      //doDeleteTemplates,
      doAddTemplate,
      doEditTemplate,
      doDetailTemplate,
      doTemplateItem,
      saveCallBack,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>